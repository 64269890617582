import axios, { AxiosInstance, AxiosError, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from "axios";
import Vue from "vue";
import { settings } from "@/settings";
import { scopes, msalService } from "./MsalService";

export class BaseClient {
    protected apiClient: AxiosInstance;

    protected constructor(url: string) {
        this.apiClient = axios.create({
            baseURL: `${settings.webApi.baseUrl}${url}`,
            responseType: "json",
        });

        this.apiClient.interceptors.request.use(this.requestInterceptor);
        this.apiClient.interceptors.response.use(undefined, this.blobErrorResponseInterceptor);
        this.apiClient.interceptors.response.use(undefined, this.errorResponseInterceptor);
    }

    protected async get<T>(service: string, errorMessage?: string): Promise<T | null> {
        try {
            const response = await this.apiClient.get<T>(service, {});
            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
            else {
                throw response;
            }
        }
        catch (error: any) {
            // Notify only if error is NOT 401 Unauthorized
            if (!axios.isAxiosError(error) || error.response.status !== 401) {
                /* Vue.notify({
                    title: "Error",
                    text: errorMessage ?? "Error while fetching data",
                    data: { status: "danger" },
                }); */
            }
        }

        return null;
    }

    protected async post<T>(service: string, data?: any, errorMessage?: string): Promise<(T | null)> {
        try {
            const response = await this.apiClient.post<T>(service, data, {});

            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
            else {
                throw response;
            }
        }
        catch (error: any) {
            // Notify only if error is NOT 401 Unauthorized
            if (error instanceof AxiosError || error.response.status !== 401) {
                Vue.notify({
                    title: "Error",
                    text: errorMessage ?? "Error while sending data",
                    data: { status: "danger" },
                });
            }
        }

        return null;
    }

    protected async put<T>(service: string, data?: any, errorMessage?: string): Promise<(T | null)> {
        try {
            const response = await this.apiClient.put<T>(service, data, {});

            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
            else {
                throw response;
            }
        }
        catch (error: any) {
            // Notify only if error is NOT 401 Unauthorized
            if (error instanceof AxiosError || error.response.status !== 401) {
                Vue.notify({
                    title: "Error",
                    text: errorMessage ?? "Error while sending data",
                    data: { status: "danger" },
                });
            }
        }

        return null;
    }

    protected async delete(service: string): Promise<AxiosResponse | null> {
        try {
            const response = await this.apiClient.delete(service, {});

            if (response.status >= 200 && response.status < 300) {
                return response;
            }
            else {
                throw response;
            }
        }
        catch (error: any) {
            // Notify only if error is NOT 401 Unauthorized
            if (!axios.isAxiosError(error) || error.response.status !== 401) {
                Vue.notify({
                    title: error.response.data.title,
                    text: error.response.data.detail,
                    data: { status: "danger" },
                });
            }

            return error.response.data.detail;
        }
    }

    protected async downloadFile(service: string, source?: CancelTokenSource, errorMessage?: string): Promise<AxiosResponse> {
        try {
            return await this.apiClient.get<Blob>(service, { cancelToken: source?.token, responseType: "blob" });
        }
        catch (error: any) {
            if (error instanceof AxiosError) {
                Vue.notify({
                    title: "Download error",
                    text: error?.response.data.detail ?? errorMessage ?? "",
                    data: { status: "danger" },
                });

                return error.response;
            }
        }

        return null;
    }

    protected buildQueryArrayParameter(name: string, values: (string | number | boolean)[]): string {
        if (values == null || values.length === 0) {
            return "";
        }

        const parameters = values.filter(value => value != null)
            .map(value => `${name}=${encodeURIComponent(value)}`);

        return parameters.join("&");
    }

    protected buildQueryObjectParameter(values: Record<string, (string | number | boolean)>): string {
        if (values == null || values.length === 0) {
            return "";
        }

        const parameters = Object.keys(values)
            .filter(key => values[key] != null)
            .map(key => `${key}=${encodeURIComponent(values[key])}`);

        return parameters.join("&");
    }

    private async requestInterceptor(request: AxiosRequestConfig) {
        const accessToken = await msalService.getAccessToken(scopes);

        // Add Authorization token
        if (accessToken != null) {
            request.headers.Authorization = `Bearer ${accessToken}`;
        }

        return Promise.resolve(request);
    }

    private blobErrorResponseInterceptor(error: AxiosError<any, any>) {
        const contentType: string = error.response.headers["content-type"]?.toLowerCase();
        if (error.request.responseType === "blob" && contentType.includes("json")) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = () => {
                    error.response.data = JSON.parse(reader.result as string);
                    resolve(Promise.reject(error));
                };

                reader.onerror = () => reject(error);

                reader.readAsText(error.response.data);
            });
        }

        return Promise.reject(error);
    }

    private async errorResponseInterceptor(error: AxiosError) {
        // If the user is unauthenticated, retry login
        if (error.response?.status === 401) {
            await msalService.getAccessToken(scopes);
        }

        return Promise.reject(error);
    }
}
