import { AxiosResponse, CancelTokenSource } from "axios";
import { BaseClient } from "./BaseClient";
import {
    ICampaignsFilter,
    ICampaignTaskItem,
    ICampaignTasksExportFilter,
    ICampaignTasksFilter,
    ICampaignTasksPage,
    IStartImport,
    IUpdateTaskItem,
    ICampaignTaskItemDefinition,
    ICampaignTouchPointGroup,
    ICampaignTouchPointGroupPage,
} from "@/models";

class CampaignTasksClient extends BaseClient {
    public constructor() {
        super("/campaign-tasks");
    }

    public async getCampaignsTasks(filter: ICampaignTasksFilter): Promise<ICampaignTasksPage> {
        const url = this.buildCampaignTasksQueryUrl("", filter);
        return await this.get<ICampaignTasksPage>(url, "Error while fetching tasks");
    }

    public async getCampaignTasks(campaignId: number, filter: ICampaignTasksFilter): Promise<ICampaignTasksPage> {
        const url = this.buildCampaignTasksQueryUrl("/" + campaignId, filter);
        return await this.get<ICampaignTasksPage>(url, "Error while fetching tasks");
    }

    public async getCompletedTaskItems(campaignId: number, filter: ICampaignTasksFilter): Promise<ICampaignTasksPage> {
        const url = this.buildCampaignTasksQueryUrl( `/${campaignId}/completed`, filter);
        return await this.get<ICampaignTasksPage>(url, "Error while fetching tasks");
    }

    public async getCampaignTouchpointGroupTasks(campaignId: number, filter: ICampaignTasksFilter): Promise<ICampaignTasksPage> {
        const url = this.buildCampaignTasksQueryUrl( `/${campaignId}/touchpoint-group-tasks`, filter);
        return await this.get<ICampaignTasksPage>(url, "Error while fetching campaign touchpoint's tasks");
    }

    public async getCampaignTouchointGroups(campaignId: number, filter: ICampaignTasksFilter): Promise<ICampaignTouchPointGroupPage> {
        const url = this.buildCampaignTasksQueryUrl(`/${campaignId}/touchpoint-groups`, filter);
        return await this.get<ICampaignTouchPointGroupPage>(url, "Error while fetching tasks");
    }


    public async getCompletedTaskItemsReferences(campaignId: number): Promise<ICampaignTaskItemDefinition[]> {
        const url = `/${campaignId}/references/completed`;
        return await this.get<ICampaignTaskItemDefinition[]>(url, "Error while fetching tasks");
    }

    public async getCampaignTaskGroupItems(campaignId: number): Promise<ICampaignTaskItem[]> {
        const url = `/${campaignId}/group/tasks`;
        return await this.get<ICampaignTaskItem[]>(url, "Error while fetching group's tasks");
    }

    public async getAutoSplitTasks(campaignId: number): Promise<ICampaignTaskItem[]> {
        const url = `/${campaignId}/auto-split-tasks`;
        return await this.get<ICampaignTaskItem[]>(url, "Error while fetching auto split tasks");
    }

    public async updateCampaignTaskItem(campaignId: number, campaignTaskItemId: number, data: IUpdateTaskItem): Promise<AxiosResponse> {
        const url = `/${campaignId}/tasks/${campaignTaskItemId}`;
        return await this.post(url, data, "Error while submitting task data");
    }

    public async updateCampaignTaskItemsAutoSplit(campaignId: number, data: IUpdateTaskItem[]): Promise<AxiosResponse> {
        const url = `/${campaignId}/tasks/auto-split`;
        return await this.post(url, data, "Error while submitting task data");
    }


    public async downloadTemplate(campaignId: number, source?: CancelTokenSource): Promise<AxiosResponse> {
        const url = `/${campaignId}/template`;
        return await this.downloadFile(url, source);
    }

    public async downloadCampaigns(fileName: string, filter: ICampaignsFilter): Promise<AxiosResponse> {
        let url = "/export-campaigns";
        url = this.buildCampaignTasksQueryUrl(url, filter);
        if (fileName.length > 0) {
            url += `&FileName=${fileName}`;
        }

        return await this.downloadFile(url);
    }

    public async downloadAllTasks(fileName: string, baseUrl: string, filter: ICampaignTasksExportFilter, source?: CancelTokenSource): Promise<AxiosResponse> {
        let url = "/export-all-tasks";
        url = this.buildCampaignTasksQueryUrl(url, filter);
        if (fileName.length > 0) {
            url += `&FileName=${fileName}`;
        }
        if (baseUrl.length > 0) {
            url += `&BaseUrl=${baseUrl}`;
        }
        return await this.downloadFile(url, source);
    }

    public async startImport(campaignId: number, data: IStartImport): Promise<void> {
        const url = `/${campaignId}/start-import`;
        return await this.post(url, data, "Error while starting import");
    }

    public async getCampaignTasksPerTouchPoints(campaignId: number): Promise<ICampaignTouchPointGroup[]> {
        const url = `/campaigns/${campaignId}/tasks-per-touchpoints`;
        return await this.get<ICampaignTouchPointGroup[]>(url, "Error while fetching tasks");
    }

    private buildCampaignTasksQueryUrl(service: string, filter: ICampaignTasksFilter): string {
        const queryString = Object.keys(filter).map((key) => {
            const value = filter[key];
            if (value == null) {
                return "";
            }
            else if (Array.isArray(value)) {
                if (value.length > 0) {
                    return value.map(v => `${key}=${encodeURIComponent(v)}`).join("&");
                }
                else {
                    return "";
                }
            }
            else if (value instanceof Date) {
                return `${key}=${encodeURIComponent(value.toISOString())}`;
            }
            else {
                return `${key}=${encodeURIComponent(value)}`;
            }
        });

        const url = `${service}?${queryString.filter(s => s?.length > 0).join("&")}`;
        return url;
    }
}

const campaignTasksClient = new CampaignTasksClient();

export { CampaignTasksClient, campaignTasksClient };
