import { ICampaignValidationPage, ICampaignValidationFilter, ICampaignValidation, ICampaignReferences, ICampaignValidationData } from "@/models";
import {
    ICampaignTaskItemValidationByExternalCode,
    ICampaignTouchPointGroupTask,
    ICampaignValidationTasksAgglomeratedByExternalCode,
    ITouchPointDetailStats,
    ITouchPointDetailValidationFilter,
    ITouchPointsStats,
} from "@/models/dataValidation";
import { ICampaignTouchPointGroups } from "@/models/dataValidation/ICampaignTouchPointGroups";
import { ITouchPointDetailGlobalStats } from "@/models/dataValidation/ITouchPointDetailGlobalStats";
import { ITouchPointsValidationAPIFilter } from "@/models/dataValidation/ITouchPointsValidationAPIFilter";
import { IIndicatorStats } from "@/models/IIndicatorStats";
import { AxiosResponse } from "axios";
import { BaseClient } from "./BaseClient";
import { format } from "date-fns";

class DataValidationClient extends BaseClient {

    public constructor() {
        super("/data-validation");
    }

    public async getCampaigns(filter: ICampaignValidationFilter): Promise<ICampaignValidationPage> {
        const url = this.buildCampaignsQueryUrl("/campaigns", filter);
        return await this.get<ICampaignValidationPage>(url, "Error while fetching activations");
    }

    public async getCampaign(campaignId: number): Promise<ICampaignValidation | null> {
        return await this.get<ICampaignValidation | null>("/campaigns/" + campaignId, "Could not find activation");
    }

    public async getCampaignsIndicatorStats(filter: ICampaignValidationFilter): Promise<IIndicatorStats> {
        const url = this.buildCampaignsQueryUrl("validation-indicators", filter);
        return await this.get<IIndicatorStats>(url, "Error while fetching indicators");
    }

    public async getCampaignTasksPerTouchPoints(campaignId: number, filter: ICampaignValidationFilter): Promise<ICampaignTouchPointGroups> {
        const url = this.buildCampaignsQueryUrl(`/campaigns/${campaignId}/tasks-per-touchpoints`, filter);
        return await this.get<ICampaignTouchPointGroups>(url, "Error while fetching tasks");
    }

    public async getCampaignTasksPerTouchPointId(campaignId: number, filter: ICampaignValidationFilter): Promise<ICampaignTouchPointGroupTask[]> {
        const url = this.buildCampaignsQueryUrl(`/campaigns/${campaignId}/tasks-per-touchpoint-id`, filter);
        return await this.get<ICampaignTouchPointGroupTask[]>(url, "Error while fetching tasks");
    }

    public async validateTaskItems(campaignTaskItemIds: number[]): Promise<void[]> {
        return await this.post("/tasks/validate", campaignTaskItemIds, "Could not validate tasks");
    }

    public async rejectTaskItems(campaignTaskItemIds: number[], rejectCommentary?: string): Promise<void[]> {
        return await this.post("/tasks/reject", {  ids: campaignTaskItemIds, commentary: rejectCommentary }, "Could not reject tasks");
    }

    public async getTouchpointsIndicatorStats(filter: ITouchPointsValidationAPIFilter): Promise<IIndicatorStats> {
        const url = this.buildCampaignsQueryUrl("/touchpoints-indicator-stats", filter);
        return await this.get<IIndicatorStats>(url, "Could not find indicator stats");
    }

    public async getTouchPointsStats(filter: ITouchPointsValidationAPIFilter): Promise<ITouchPointsStats[]> {
        const url = this.buildCampaignsQueryUrl("/touchpoints-stats", filter);
        return await this.get<ITouchPointsStats[]>(url, "Could not find Touchpoints");
    }

    public async getTouchPointDetailStats(filter: Partial<ITouchPointDetailValidationFilter>): Promise<ITouchPointDetailStats> {
        const url = this.buildCampaignsQueryUrl("/touchpoint-detail-stats", filter);
        return await this.get<ITouchPointDetailStats>(url, "Could not find Touchpoint details");
    }

    public async getTouchPointDetailGlobalStats(filter: Partial<ITouchPointDetailValidationFilter>): Promise<ITouchPointDetailGlobalStats> {
        const url = this.buildCampaignsQueryUrl("/touchpoint-detail-global-stats", filter);
        return await this.get<ITouchPointDetailGlobalStats>(url, "Could not find Touchpoint global details");
    }

    public async getCampaignsValidationTotal(filter: ICampaignValidationFilter): Promise<number> {
        const url = this.buildCampaignsQueryUrl("total", filter);
        return null;
        /* XTRT-1983 - Improved DataValidation Performance
        return await this.get<number>(url, "Error while fetching activations"); */
    }

    public async downloadExport(fileName: string, filter: ICampaignValidationFilter): Promise<AxiosResponse> {
        let url = this.buildCampaignsQueryUrl("/campaigns/export", filter);
        if (fileName.length > 0) {
            url += `&FileName=${fileName}`;
        }

        return await this.downloadFile(url);
    }

    public async getCampaignsReferences(filter: ICampaignValidationFilter): Promise<ICampaignReferences | null> {
        const url = this.buildCampaignsQueryUrl("/campaigns/references", filter);
        return await this.get<ICampaignReferences>(url, "Could not find references");
    }

    public async getCampaignValidationData(campaignValidationDataGroupId: string, subMarketAggregationEnabled: boolean): Promise<ICampaignValidationData | null> {
        const url = this.buildCampaignsQueryUrl(`/tasks/validation-data/${campaignValidationDataGroupId}`, { subMarketAggregationEnabled });
        return await this.get<ICampaignValidationData>(url, "Could not find validation data");
    }

    public async getCampaignValidationTasksAgglomeratedByExternalCode(campaignId: number): Promise<ICampaignValidationTasksAgglomeratedByExternalCode[]> {
        return await this.get<ICampaignValidationTasksAgglomeratedByExternalCode[]>(`tasks/validation-data/agglomerated-by-external-code/${campaignId}`, "Could not find offer code data");
    }

    public async getTasksByCode(campaignId: number, externalCode: string): Promise<ICampaignTaskItemValidationByExternalCode[]> {
        return await this.get<ICampaignTaskItemValidationByExternalCode[]>(`tasks/validation-data/${campaignId}/${externalCode}`,"Could not find offer code tasks data");
    }

    private buildCampaignsQueryUrl(service: string, filter: any): string {
        let queryNestedObject = null;
        const queryString = Object.keys(filter).map((key) => {
            const value = filter[key];
            if (key === "validationScope") {
                if (value instanceof Object && value !== null) {
                    queryNestedObject = Object.keys(value).map((keyNested) => {
                        const valueNested = value[keyNested];
                        if (valueNested == null) {
                            return "";
                        }
                        else if (Array.isArray(valueNested)) {
                            if (valueNested.length > 0) {
                                return valueNested.map(v => `${key}.${keyNested}=${encodeURIComponent(v)}`).join("&");
                            }
                            else {
                                return "";
                            }
                        }
                        else {
                            return `${key}.${keyNested}=${encodeURIComponent(valueNested)}`;
                        }
                    });
                }
            }
            if (value == null) {
                return "";
            }
            else if (Array.isArray(value)) {
                if (value.length > 0) {
                    return value.map(v => `${key}=${encodeURIComponent(v)}`).join("&");
                }
                else {
                    return "";
                }
            }
            else if(key==="startDate" || key==="endDate"){
                return `${key}=${encodeURIComponent(format(new Date(value), "yyyy-MM-dd HH:mm:ss"))}`;
            }

            else if (value instanceof Date) {
                return `${key}=${encodeURIComponent(value.toISOString())}`;
            }
            else {
                return `${key}=${encodeURIComponent(value)}`;
            }
        });


        const url = `${service}?${queryString.filter(s => s?.length > 0).join("&")}&${queryNestedObject !== null ? queryNestedObject.filter(s => s?.length > 0).join("&"): ""}`;
        return url;
    }
}

const dataValidationClient = new DataValidationClient();

export { DataValidationClient, dataValidationClient };
