import { ICampaignsPage, ICampaignsFilter, ICampaign, ICampaignDefinition, ISaveCampaign, ICampaignReferences, ILightCampaignDefinition, ICampaignL3FAutoSplitTouchPoint } from "@/models";
import { IIndicatorStats } from "@/models/IIndicatorStats";
import { AxiosResponse } from "axios";
import { BaseClient } from "./BaseClient";
import { ITouchPointPubCustEdition } from "@/models/ITouchPointPubCustEdition";
import { format } from "date-fns";

class CampaignsClient extends BaseClient {

    public constructor() {
        super("/campaigns");
    }

    public async createCampaign(campaign: ISaveCampaign): Promise<ICampaign> {
        return await this.post("", campaign, "Could not save activation");
    }

    public async getCampaign(campaignId: number): Promise<ICampaign | null> {
        return await this.get<ICampaign | null>("/" + campaignId, "Could not find activation");
    }

    public async getCampaignAutoSplit(campaignId: number): Promise<ICampaignL3FAutoSplitTouchPoint[]> {
        return await this.get<ICampaignL3FAutoSplitTouchPoint[]>(`/${campaignId}/auto-split`);
    }

    public async getCampaigns(filter: ICampaignsFilter): Promise<ICampaignsPage> {
        const url = this.buildCampaignsQueryUrl("", filter);
        return await this.get<ICampaignsPage>(url, "Error while fetching activations");
    }

    public async getCampaignsIndicatorStats(filter: ICampaignsFilter): Promise<IIndicatorStats> {
        const url = this.buildCampaignsQueryUrl("collection-indicators", filter);
        return await this.get<IIndicatorStats>(url, "Error while fetching indicators");
    }

    public async getMyPendingCampaigns(): Promise<ICampaignDefinition[]> {
        const campaigns = await this.get<ICampaignDefinition[]>("/my-pending", "Error while fetching pending activations");
        return campaigns ?? [];
    }

    public async getCampaignsReferences(filter: ICampaignsFilter): Promise<ICampaignReferences | null> {
        const url = this.buildCampaignsQueryUrl("/references", filter);
        return await this.get<ICampaignReferences>(url, "Could not find references");
    }

    public async reopenCampaign(campaignId: number): Promise<void> {
        return await this.post("/" + campaignId + "/reopen", "Could not reopen activation");
    }

    public async getReferences(campaignId: number, filter: ICampaignsFilter | null): Promise<ICampaignReferences | null> {
        let url = `/${campaignId}/references`;

        if (filter != null) {
            url  = this.buildCampaignsQueryUrl(url, filter);
        }
        return await this.get<ICampaignReferences | null>(url, "Could not find references");
    }

    public async getCompleteReferences(campaignId: number, filter: ICampaignsFilter | null): Promise<ICampaignReferences | null> {
        let url = `/${campaignId}/references/complete`;
        if (filter != null) {
            url  = this.buildCampaignsQueryUrl(`/${campaignId}/references/complete`, filter);
        }
        return await this.get<ICampaignReferences | null>(url, "Could not find references");
    }

    public async getValidatedReferences(campaignId: number, filter: ICampaignsFilter | null): Promise<ICampaignReferences | null> {
        let url = `/${campaignId}/references/validated`;
        if (filter != null) {
            url  = this.buildCampaignsQueryUrl(`/${campaignId}/references/validated`, filter);
        }
        return await this.get<ICampaignReferences | null>(url, "Could not find references");
    }

    public async getIncompleteReferences(filter: ICampaignsFilter | null, campaignId?: number): Promise<ICampaignReferences | null> {
        if (campaignId) {
            return await this.get<ICampaignReferences | null>(this.buildCampaignsQueryUrl(`/${campaignId}/references/incomplete`, filter), "Could not find references");
        }
    }

    public async getCampaignByTaskId(taskId: number): Promise<ILightCampaignDefinition | null> {
        const url = `/task-id/${taskId}`;
        return await this.get<ILightCampaignDefinition | null>(url, "activation not found");
    }

    public async updateCampaign(campaignId: number, campaign: ISaveCampaign): Promise<void> {
        return await this.post("/" + campaignId, campaign, "Could not update activation");
    }

    public async updateTouchPointsPublishersCustomers(campaignId: number, campaignTouchPointPubOrCustEdit: ITouchPointPubCustEdition[]): Promise<void> {
        return await this.post("/updateTouchPointsPublishersCustomers/" + campaignId, campaignTouchPointPubOrCustEdit, "Could not update touchpoints publishers and customers");
    }

    public async deleteCampaign(campaignId: number): Promise<AxiosResponse> {
        return await this.delete("/" + campaignId);
    }

    public async getCampaignsTotal(filter: ICampaignsFilter): Promise<number> {
        const url = this.buildCampaignsQueryUrl("total", filter);
        return null;
        /* XTRT-2210 - Improved DataValidation Performance
        return await this.get<number>(url, "Error while fetching activations");*/
    }

    private buildCampaignsQueryUrl(service: string, filter: ICampaignsFilter): string {
        const queryString = Object.keys(filter).map((key) => {
            const value = filter[key];
            if (value == null) {
                return "";
            }
            else if (Array.isArray(value)) {
                if (value.length > 0) {
                    return value.map(v => `${key}=${encodeURIComponent(v)}`).join("&");
                }
                else {
                    return "";
                }
            }
            else if(key==="startDate" || key==="endDate"){
                return `${key}=${encodeURIComponent(format(new Date(value), "yyyy-MM-dd HH:mm:ss"))}`;
            }
            else if (value instanceof Date) {
                return `${key}=${encodeURIComponent(value.toISOString())}`;
            }
            else {
                return `${key}=${encodeURIComponent(value)}`;
            }
        });

        const url = `${service}?${queryString.filter(s => s?.length > 0).join("&")}`;
        return url;
    }
}

const campaignsClient = new CampaignsClient();

export { CampaignsClient, campaignsClient };
