import { PrDateShorcut } from "@pernod-ricard/design-system/dist/types/components/pr-datepicker/pr-datepicker-type";
import { addMonths, differenceInCalendarMonths, endOfMonth, endOfQuarter, endOfWeek, endOfYear, format, isSameDay, isValid, startOfMonth, startOfQuarter, startOfWeek, startOfYear, sub } from "date-fns";
import { dateFormat } from "./displayFormatHelper";

// Compute common dates
const today = new Date(Date.now());
let currentFY: [Date, Date] = [null, null];
let lastFy: [Date, Date] = [null, null];

if (today.getMonth() + 1 < 7) {
    currentFY = [new Date(`${today.getFullYear() - 1}-07-01`), new Date(`${today.getFullYear()}-06-30`)];
    lastFy = [new Date(`${today.getFullYear() - 2}-07-01`), new Date(`${today.getFullYear() - 1}-06-30`)];
}
else {
    currentFY = [new Date(`${today.getFullYear()}-07-01`), new Date(`${today.getFullYear() + 1}-06-30`)];
    lastFy = [new Date(`${today.getFullYear() - 1}-07-01`), new Date(`${today.getFullYear()}-06-30`)];
}

interface ICommonDates {
    today: Date;
    currentWeek: [Date, Date];
    lastWeek: [Date, Date];
    currentMonth: [Date, Date];
    lastMonth: [Date, Date];
    currentYear: [Date, Date];
    lastYear: [Date, Date];
    currentQuarter: [Date, Date];
    lastQuarter: [Date, Date];
    currentFY: [Date, Date];
    lastFy: [Date, Date];
}

export const commonDates = Object.freeze({
    today,
    currentWeek: [startOfWeek(today, { weekStartsOn: 1 }), endOfWeek(today, { weekStartsOn: 1 })],
    lastWeek: [startOfWeek(sub(today, { days: 7 }), { weekStartsOn: 1 }), endOfWeek(sub(today, { days: 7 }), { weekStartsOn: 1 })],
    currentMonth: [startOfMonth(today), endOfMonth(today)],
    lastMonth: [startOfMonth(addMonths(today,-1)), endOfMonth(addMonths(today, -1))],
    currentYear: [startOfYear(today), endOfYear(today)],
    lastYear: [startOfYear(sub(today, { years: 1 })), endOfYear(sub(today, { years: 1 }))],
    currentQuarter: [startOfQuarter(today), endOfQuarter(today)],
    lastQuarter: [startOfQuarter(sub(today, { months: 3 })), endOfQuarter(sub(today, { months: 3 }))],
    currentFY,
    lastFy,
} as ICommonDates);

export const shortcuts = () => {
    const datepickerShortcuts: PrDateShorcut[] = [];

    // this week
    datepickerShortcuts.push({
        "label": "This week",
        "actionsFirst": [{ "action": "startOf", "amount": null, "type": "week" }],
        "actionsLast": [{ "action": "endOf", "amount": null, "type": "week" }],
    });

    // last week
    datepickerShortcuts.push({
        "label": "Last week",
        "actionsFirst": [
            { "action": "subtract", "amount": 1, "type": "week" },
            { "action": "startOf", "amount": null, "type": "week" },
        ],
        "actionsLast": [
            { "action": "subtract", "amount": 1, "type": "week" },
            { "action": "endOf", "amount": null, "type": "week" },
        ],
    });

    // this month
    datepickerShortcuts.push({
        "label": "This month",
        "actionsFirst": [{ "action": "startOf", "amount": null, "type": "month" }],
        "actionsLast": [{ "action": "endOf", "amount": null, "type": "month" }],
    });

    // last month
    datepickerShortcuts.push({
        "label": "Last month",
        "actionsFirst": [
            { "action": "subtract", "amount": 1, "type": "month" },
            { "action": "startOf", "amount": null, "type": "month" },
        ],
        "actionsLast": [
            { "action": "subtract", "amount": 1, "type": "month" },
            { "action": "endOf", "amount": null, "type": "month" },
        ],
    });

    // this year
    datepickerShortcuts.push({
        "label": "This Year",
        "actionsFirst": [{ "action": "startOf", "amount": null, "type": "year" }],
        "actionsLast": [{ "action": "endOf", "amount": null, "type": "year" }],
    });

    // last year
    datepickerShortcuts.push({
        "label": "Last Year",
        "actionsFirst": [
            { "action": "subtract", "amount": 1, "type": "year" },
            { "action": "startOf", "amount": null, "type": "year" },
        ],
        "actionsLast": [
            { "action": "subtract", "amount": 1, "type": "year" },
            { "action": "endOf", "amount": null, "type": "year" },
        ],
    });

    // this quarter
    datepickerShortcuts.push({
        "label": "This Quarter",
        "actionsFirst": [
            { "action": "subtract", "amount": differenceInCalendarMonths(today, commonDates.currentQuarter[0]), "type": "month" },
            { "action": "startOf", "amount": null, "type": "month" },
        ],
        "actionsLast": [
            { "action": "subtract", "amount": differenceInCalendarMonths(today, commonDates.currentQuarter[1]), "type": "month" },
            { "action": "endOf", "amount": null, "type": "month" },
        ],
    });

    // last quarter
    datepickerShortcuts.push({
        "label": "Last Quarter",
        "actionsFirst": [
            { "action": "subtract", "amount": differenceInCalendarMonths(today, commonDates.lastQuarter[0]), "type": "month" },
            { "action": "startOf", "amount": null, "type": "month" },
        ],
        "actionsLast": [
            { "action": "subtract", "amount": differenceInCalendarMonths(today, commonDates.lastQuarter[1]), "type": "month" },
            { "action": "endOf", "amount": null, "type": "month" },
        ],
    });

    // this FY
    datepickerShortcuts.push({
        "label": "This FY",
        "actionsFirst": [
            { "action": "subtract", "amount": differenceInCalendarMonths(today, currentFY[0]), "type": "month" },
            { "action": "startOf", "amount": null, "type": "month" },
        ],
        "actionsLast": [
            { "action": "subtract", "amount": differenceInCalendarMonths(today, currentFY[1]), "type": "month" },
            { "action": "endOf", "amount": null, "type": "month" },
        ],
    });

    // last FY
    datepickerShortcuts.push({
        "label": "Last FY",
        "actionsFirst": [
            { "action": "subtract", "amount": differenceInCalendarMonths(today, lastFy[0]), "type": "month" },
            { "action": "startOf", "amount": null, "type": "month" },
        ],
        "actionsLast": [
            { "action": "subtract", "amount": differenceInCalendarMonths(today, lastFy[1]), "type": "month" },
            { "action": "endOf", "amount": null, "type": "month" },
        ],
    });

    return datepickerShortcuts;
};

export const isShortcut = (shortcutsTitle: ShortcutName, dates: Date[]): boolean => {

    switch (shortcutsTitle) {
        case "This week":
            return isSameDay(dates[0], commonDates.currentWeek[0]) && isSameDay(dates[1], commonDates.currentWeek[1]);

        case "Last week":
            return isSameDay(dates[0], commonDates.lastWeek[0]) && isSameDay(dates[1], commonDates.lastWeek[1]);

        case "This month":
            return isSameDay(dates[0], commonDates.currentMonth[0]) && isSameDay(dates[1], commonDates.currentMonth[1]);

        case "Last month":
            return isSameDay(dates[0], commonDates.lastMonth[0]) && isSameDay(dates[1], commonDates.lastMonth[1]);

        case "This Year":
            return isSameDay(dates[0], commonDates.currentYear[0]) && isSameDay(dates[1], commonDates.currentYear[1]);

        case "Last Year":
            return isSameDay(dates[0], commonDates.lastYear[0]) && isSameDay(dates[1], commonDates.lastYear[1]);

        case "This Quarter":
            return isSameDay(dates[0], commonDates.currentQuarter[0]) && isSameDay(dates[1], commonDates.currentQuarter[1]);

        case "Last Quarter":
            return isSameDay(dates[0], commonDates.lastQuarter[0]) && isSameDay(dates[1], commonDates.lastQuarter[1]);

        case "This FY":
            return isSameDay(dates[0], currentFY[0]) && isSameDay(dates[1], currentFY[1]);

        case "Last FY":
            return isSameDay(dates[0], lastFy[0]) && isSameDay(dates[1], lastFy[1]);

        default:
            return false;
    }
};

export const getDatesOrShortcutName = (dates: Date[]): ShortcutName | string => {
    if (!dates) {
        return null;
    }

    const isThisWeek = isSameDay(dates[0], commonDates.currentWeek[0]) && isSameDay(dates[1], commonDates.currentWeek[1]);
    const isLastWeek = isSameDay(dates[0], commonDates.lastWeek[0]) && isSameDay(dates[1], commonDates.lastWeek[1]);
    const isThisMonth = isSameDay(dates[0], commonDates.currentMonth[0]) && isSameDay(dates[1], commonDates.currentMonth[1]);
    const isLastMonth = isSameDay(dates[0], commonDates.lastMonth[0]) && isSameDay(dates[1], commonDates.lastMonth[1]);
    const isThisYear = isSameDay(dates[0], commonDates.currentYear[0]) && isSameDay(dates[1], commonDates.currentYear[1]);
    const isLastYear = isSameDay(dates[0], commonDates.lastYear[0]) && isSameDay(dates[1], commonDates.lastYear[1]);
    const isThisQuarter = isSameDay(dates[0], commonDates.currentQuarter[0]) && isSameDay(dates[1], commonDates.currentQuarter[1]);
    const isLastQuarter = isSameDay(dates[0], commonDates.lastQuarter[0]) && isSameDay(dates[1], commonDates.lastQuarter[1]);
    const isThisFY = isSameDay(dates[0], currentFY[0]) && isSameDay(dates[1], currentFY[1]);
    const isLastFY = isSameDay(dates[0], lastFy[0]) && isSameDay(dates[1], lastFy[1]);

    if (isThisWeek) {
        return "This week";
    }
    else if (isLastWeek) {
        return "Last week";
    }
    else if (isThisMonth) {
        return "This month";
    }
    else if (isLastMonth) {
        return "Last month";
    }
    else if (isThisYear) {
        return "This Year";
    }
    else if (isLastYear) {
        return "Last Year";
    }
    else if (isThisQuarter) {
        return "This Quarter";
    }
    else if (isLastQuarter) {
        return "Last Quarter";
    }
    else if (isThisFY) {
        return "This FY";
    }
    else if (isLastFY) {
        return "Last FY";
    }
    else {
        return `${isValid(dates[0]) ? format(dates[0], dateFormat()) : ""} - ${isValid(dates[1]) ? format(dates[1], dateFormat()) : ""}`;
    }
};

export type ShortcutName = "This week"
| "Last week"
| "This month"
| "Last month"
| "This Year"
| "Last Year"
| "This Quarter"
| "Last Quarter"
| "This FY"
| "Last FY";
